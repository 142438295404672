<template>
  <form @submit.prevent="login">
    <div class="login-form">
      <!-- Username -->
      <VField>
        <VControl icon="feather:user">
          <input
              id="email"
              v-model="email"
              class="input"
              type="text"
              placeholder="Username"
              autocomplete="username"
          />
        </VControl>
      </VField>

      <!-- Password -->
      <VField>
        <VControl icon="feather:lock">
          <input
              id="password"
              v-model="password"
              class="input"
              type="password"
              placeholder="Password"
              autocomplete="current-password"
          />
        </VControl>
      </VField>

      <!-- Switch -->
      <VControl class="setting-item">
        <label for="remember-me" class="form-switch is-primary">
          <input
              id="remember-me"
              type="checkbox"
              class="is-switch"
          />
          <i aria-hidden="true"></i>
        </label>
        <div class="setting-meta">
          <label for="remember-me">
            <span>Remember Me</span>
          </label>
        </div>
      </VControl>

      <!-- Submit -->
      <VControl class="login">
        <VButton
            :loading="isLoading"
            color="primary"
            type="submit"
            bold
            fullwidth
            raised
        >
          Sign In
        </VButton>
      </VControl>

      <div class="forgot-link has-text-centered">
        <router-link :to="{ name: 'forgot' }" class="has-text-white">Forgot Password?</router-link>
      </div>
    </div>
  </form>

</template>
<script>


import { defineComponent } from 'vue'
import VButton from '../../components/base/button/VButton.vue'
import VField from '../../components/base/form/VField.vue'
import VControl from '../../components/base/form/VControl.vue'
import {mapActions} from "vuex";
import store, {ActionTypes} from '../../store'
export default defineComponent({
  name: 'LoginForm',

  components: {
    VButton,
    VField,
    VControl
  },
  data () {
    return {
      email: "",
      password: "",
      submit: false
    }
  },
  methods: {
    ... mapActions( {
      login : ActionTypes.ADD_USER_DATA ,
    } ) ,
    login (e)  {
      e.preventDefault();
      this.submit = true;
      const payload = { email: this.email, password: this.password }
      store.dispatch(ActionTypes.ADD_USER_DATA, payload).then((data) => {
        this.$router.push({ name: 'Index' })
      })
    }
  },


});
</script >

<style lang="scss">
@import '../../scss/abstracts/_variables.scss';
@import '../../scss/abstracts/_mixins.scss';
@import '../../scss/pages/auth/_auth.scss';

form .svgContainer {
  position: relative;
  width: 200px;
  height: 200px;
  margin: 0 auto 1em;
  border-radius: 50%;
  background: none;
  border: solid 2.5px #3A5E77;
  overflow: hidden;
  pointer-events: none;
  display: none;
}
form .svgContainer div {
  position: relative;
  width: 100%;
  height: 0;
  overflow: hidden;
  padding-bottom: 100%;
}
form .svgContainer .mySVG {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}


</style>
